import React from 'react';
import { makeStyles } from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import TimeIcon from '@material-ui/icons/AccessTime';
import LocationIcon from '@material-ui/icons/LocationOn';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import withWidth from '@material-ui/core/withWidth';

const ActivityCard = (props) => {
    const classes = useStyles(props);
    const className = `${['xs', 'sm', 'md'].includes(props.width) ? 'noHover' : ''} activityCard ${classes.activityCard} ${props.location.pathname === '/' || props.location.pathname.includes('signin') ? "" : `${classes.activityCardMobile}`} ${props.className}`;

    if (props.noData) {
        return (
            <div className={className}>
                <div className="clickWrapper" >
                    <div className="imgArea" >
                        <div className="bgImage"></div>
                    </div>
                    <div className="infoArea" >
                        <h4 className="cardTitle">
                            請等待更多最新活動
                        </h4>
                    </div>
                </div>
            </div>
        );
    }

    let joinProgressArea = props.noProgressBar ? null : props.max ? (
        <div className="joinProgressArea">
            <div className="joinProgressText" >
                {
                    props.enrolledActivities && (props.id in props.enrolledActivities) ?
                        <div className="enroll-label">
                            <CheckCircleIcon fontSize="small" />
                            已報名
                        </div> : null
                }
                <div className="signupCount-label">
                    {`${props.signupCount < props.max ? '報名人數' : '已額滿'} ${props.signupCount}/${props.max}`}
                </div>
            </div>
            <LinearProgress
                className="progressBar"
                variant="determinate"
                value={props.signupCount < props.max ? props.signupCount / props.max * 100 : 100}
            />
        </div>
    ) : (
        <div className="joinProgressArea">
            <p className="liveText" style={{ fontSize: 16 }}>現場簽到</p>
        </div>
    );

    if (!props.loading) {
        const cardContent =
            < >
                <div className="imgArea" >
                    <div className="bgImage"></div>
                </div>
                <div className="infoArea" >
                    <h4 className="cardTitle">
                        {props.name}
                    </h4>
                    <p className="infoText">
                        <TimeIcon fontSize="small" />
                        <span className="text">{props.time}</span>
                    </p>
                    <p className="infoText">
                        <LocationIcon fontSize="small" />
                        <span className="text">{props.place}</span>
                    </p>
                    <div className="chipArea">
                        {props.chips}
                    </div>
                    {joinProgressArea}
                </div>
            </>
        if (props.onClick) {
            return (
                <div className={className}>
                    <div
                        onClick={props.onClick}
                        className="clickWrapper"
                    >
                        {cardContent}
                    </div>
                    <div className="children" style={{ textAlign: 'center' }}>
                        {props.children}
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className={className}>
                    <Link
                        to={props.location.pathname.split('/')[1] === 'group' ? `/group/${props.match.params.groupId}/activityInfo/${props.id}` : `/activities/activityInfo/${props.id}`}
                        className="linkWrapper"
                    >
                        {cardContent}
                    </Link>
                    <div className="children" style={{ textAlign: 'center' }}>
                        {props.children}
                    </div>
                </div>
            );
        }

    }
    else {
        return (
            <div className={className}>
                <div className="imgArea loading" ></div>
                <div className="infoArea displayblock" >
                    <div className="cardTitle loading"></div>
                    <div className="timeInfo">
                        <p className="infoText loading"></p>
                        <p className="infoText loading"></p>
                    </div>
                </div>
            </div>
        );
    }
};

export default withWidth()(withRouter(ActivityCard));

const useStyles = makeStyles(theme => ({
    "@keyframes loading": {
        "0%": {
            backgroundPosition: "100% 50%"
        },
        "100%": {
            backgroundPosition: "0 50%"
        }
    },
    activityCard: {
        '&:active, &:hover': {
            color: theme.palette.text.primary
        },
        "& .loading": {
            position: 'relative',
            "&::after": {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                background: `linear-gradient(100deg, ${theme.palette.background.default}55 30%, ${theme.palette.background.glare}55 50%, ${theme.palette.background.default}55 70%)`,
                backgroundSize: '400%',
                animation: '$loading 1.2s ease-in-out infinite',
                top: 0,
                left: 0,
            },
        },
        "&> *": {
            color: theme.palette.text.primary,
        },
        "&:not(.noHover):hover": {
            color: theme.palette.text.primary,
            transition: 'all 0.2s',
            cursor: 'pointer',
            transform: 'scale(1.02)',
            '& .imgArea .bgImage': {
                backgroundImage: props => `linear-gradient(355deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 5%, transparent 50%, transparent 100%), url(${props.imgURL})`,
                transition: 'all 0.2s'
            }
        },
        transition: 'all 0.2s',
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        paddingBottom: 0,
        margin: 0,
        borderRadius: "15px",
        boxShadow: "0 0px 10px rgba(0, 0, 0, 0.2)",
        boxSizing: "border-box",
        overflow: "hidden",
        flexDirection: 'column',
        width: '100%',
        "& .linkWrapper, .clickWrapper": {
            display: 'initial',
        },
        "& .imgArea": {
            width: '100%',
            position: 'relative',
            maxWidth: 'none',
            paddingTop: '75%',
            marginRight: 0,
            "& .bgImage": {
                paddingTop: '75%',
                backgroundImage: props => `url(${props.imgURL})`,
                width: '100%',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: "absolute",
                left: 0,
                zIndex: 0,
                top: 0,
                objectFit: 'cover'
            },
            "&.loading": {
                backgroundColor: theme.palette.divider,
                maxHeight: 100
            }
        },
        "& .infoArea": {
            textAlign: 'left',
            flex: 6,
            minWidth: "190px",
            marginBottom: 0,
            padding: '15px',
            minHeight: '190px',
            boxSizing: 'border-box',
            "&.displayblock": {
                display: 'block'
            },
            "& .cardTitle": {
                fontWeight: 700,
                marginBottom: 10,
                lineHeight: 1.25,
                // two line clampping
                display: '-webkit-box',
                lineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                "&.loading": {
                    backgroundColor: theme.palette.divider,
                    width: '70%',
                    height: '30px',
                }
            },
            "& .infoText": {
                margin: 0,
                marginBottom: 8,
                display: "flex",
                alignItems: "center",
                fontSize: 14,
                maxWidth: "100%",
                "& > *": {
                    marginRight: 5
                },
                "& .text": {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                },
                "&.loading": {
                    backgroundColor: theme.palette.divider,
                    width: '100%',
                    height: '15px',
                }
            },
            "& .chipArea": {
                top: 0,
                left: 0,
                width: "100%",
                padding: "10px",
                boxSizing: "border-box",
                position: "absolute",

                display: 'flex',
                flexWrap: 'wrap',
                "& .chip": {
                    marginRight: '0.5rem',
                    marginBottom: '0.5rem'
                }
            }
        },
        "& .joinProgressArea": {
            fontSize: 14,
            '& .MuiLinearProgress-root': {
                backgroundColor: theme.palette.action.selected,
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
            },
            display: 'flex',
            flexDirection: 'column-reverse',
            position: 'absolute',
            left: 0,
            bottom: 10,
            width: '100%',
            padding: '0 15px',
            boxSizing: 'border-box',
            "& .joinProgressText": {
                position: 'relative',
                bottom: 0,
                textAlign: 'right',
                paddingTop: 5,
                margin: 0,
                display: 'flex',
                justifyContent: 'space-between',
                "& .enroll-label": {
                    display: 'flex',
                    alignItems: 'center',
                    "& svg": {
                        paddingRight: 3
                    }
                },
                "& .signupCount-label": {
                    marginLeft: 'auto'
                }
            },
            "& .progressBar": {
                position: 'relative',
                bottom: 0,
                height: 7.5,
                borderRadius: '50px'
            },
            "& .liveText": {
                margin: 0,
                textAlign: 'right'
            }
        },
        "& .children": {
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
        }
    },
    [theme.breakpoints.down('sm')]: {
        activityCardMobile: {
            "&:hover": {
                '& .imgArea .bgImage': {
                    backgroundImage: props => `linear-gradient(0deg, transparent 0%, transparent 5%, transparent 50%, transparent 100%), url(${props.imgURL})`,
                }
            },
            borderBottom: `solid 1px ${theme.palette.divider}`,
            boxShadow: 'none',
            position: 'relative',
            borderRadius: 0,
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            padding: "20px 5px",
            "& .linkWrapper, .clickWrapper": {
                display: 'flex',
                width: '100%',

                paddingBottom: 35
            },
            "& .imgArea": {
                paddingTop: 0,

                flex: 4,
                width: '100%',
                maxWidth: 180,
                marginRight: '10px',
                "& .bgImage": {
                    backgroundImage: props => `url(${props.imgURL})`,
                    width: '100%',
                    paddingTop: '75%',
                    backgroundSize: 'cover',
                    objectFit: 'cover'
                },
                "&.loading": {
                    backgroundColor: theme.palette.divider,
                }
            },
            "& .infoArea": {
                padding: 0,
                textAlign: 'left',
                flex: 6,
                minWidth: 190,
                minHeight: 135,
                "& .cardTitle": {
                    margin: 0,
                    fontWeight: 700,
                    // two line clampping
                    display: '-webkit-box',
                    lineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    "&.loading": {
                        backgroundColor: theme.palette.divider,
                        width: '70%',
                        height: '30px',
                    }
                },
                "& .infoText": {
                    margin: '0.5rem 0',
                    display: "flex",
                    alignItems: "center",
                    fontSize: 14,
                    maxWidth: "100%",
                    "& > *": {
                        marginRight: 5
                    },
                    "& .text": {
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    },
                    "&.loading": {
                        backgroundColor: theme.palette.divider,
                        width: '100%',
                        height: '15px',
                    }
                },
                "& .chipArea": {
                    position: 'relative',
                    padding: 0,
                    display: 'flex',
                    flexWrap: 'wrap',
                    marginTop: 10,
                    "& .chip": {
                        marginRight: '0.5rem',
                        marginBottom: '0.5rem'
                    }
                }
            },
            "& .joinProgressArea": {
                fontSize: 14,
                padding: 0,
                bottom: 10,
                width: '100%',
                '& .MuiLinearProgress-root': {
                    backgroundColor: theme.palette.action.selected,
                    left: 0,
                    bottom: 0,
                    width: '100%',
                },
                "& .joinProgressText": {
                    textAlign: 'left',
                }
            },
            "& .children": {
                bottom: 15
            }
        }
    }
}));
