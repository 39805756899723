import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core';
import Logo from '../../Logo';
import Container from '../Container';

const Footer = props => {
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <footer className={classes.Footer}>
            <Container className="container">
                <div className="contactBlock">
                    <Logo scale="1.2" />
                    <div className="contactInfo">
                        <div>聯絡電話: 03-4227151 ext. 57229</div>
                        {/* <div>信箱: ncu57235@ncu.edu.tw</div> */}
                    </div>
                </div>
                <div className="moreInfoBlock">
                    <ul className="moreInfoList">
                        <li className="title">關於</li>
                        <li><Link to="/about">關於我們</Link></li>
                        <li><Link to="/meetings">會議記錄</Link></li>
                        <li><Link to="/volunteer">服學原創</Link></li>
                        <li><Link to="/issues">服學期刊</Link></li>
                    </ul>
                    <ul className="moreInfoList">
                        <li className="title">資源</li>
                        <li><Link to="/downloads">表單下載</Link></li>
                        <li><Link to="/rules">相關辦法</Link></li>
                        <li><a target="_blank" rel="noreferrer" href="https://taidi.tycg.gov.tw/">校外服學資源</a></li>
                    </ul>
                    <ul className="moreInfoList">
                        <li className="title">幫助</li>
                        <li><Link to="/QA">常見問題</Link></li>
                        <li><a target="_blank" rel="noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLScwSskozOrop0uRSwwevEMs7wh0rWQIhFw__fXmKr6wNSyzzA/viewform?fbclid=IwAR2BiD3UdzoZFFWj7XH8pKUXCNGPHvLIZE7C9hPnJGxpG4Yg2vsxCTIAZjU">網站問題回報</a></li>
                        <li><a target="_blank" href="/manual.pdf">使用手冊</a></li>
                    </ul>
                </div>
                <div className="copyrightBlock">
                    {/* <div className="copyrightRow">
                        <Link to="/" className="copyrightLink">隱私權政策</Link>
                        <Link to="/" className="copyrightLink">使用條款</Link>
                    </div> */}
                    <div className="copyrightText">Copyright © 2021 NCU Service Learning All rights reserved</div>
                </div>

            </Container>
        </footer>
    );
};

export default Footer;

const useStyles = makeStyles(theme => ({
    Footer: {
        position: "relative",
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0 -6px 6px rgba(0, 0, 0, 0.1)",
        padding: '50px 0 20px 0',
        "& .contactInfo": {
            margin: "15px 0 30px 0"
        },
        "& .moreInfoBlock": {
            display: "flex",
            margin: "20px 0",
            flexWrap: "wrap"
        },
        "& .moreInfoList": {
            listStyle: "none",
            padding: "0",
            margin: "0 7vw 20px 0",
            flex: "26vw",
            flexShrink: "0",
            fontSize: "16px",
            "& .title": {
                fontWeight: "700",
                color: theme.palette.primary.main
            },
            "& li": {
                marginBottom: "15px",
                "& a": {
                    color: theme.palette.text.primary,
                }
            }
        },
        "& .copyrightText, & .copyrightLink": {
            fontSize: "13px",
            fontWeight: "300",
            color: theme.palette.text.disabled,
            marginRight: "10px"
        },
        "&:after": {
            content: '""',
            display: "block",
            position: "absolute",
            bottom: "0",
            left: "0",
            width: "100%",
            height: "10px",
            backgroundColor: theme => `${theme.palette.primary.main}`
        }
    },
    '@media(min-width: 768px)': {
        Footer: {
            "& .container": {
                display: "flex",
                minHeight: "300px"
            },
            "& .copyrightBlock": {
                position: "absolute",
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                bottom: "20px"
            },
            "& .moreInfoBlock": {
                flex: "8",
                margin: "0"
            },
            "& .moreInfoList": {
                flex: "1",
                "& li": {
                    marginBottom: "10px"
                }
            },
            "& .contactBlock": {
                flex: "5"
            }
        }
    }
}));
