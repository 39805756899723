import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core';
import { Icon } from "@material-ui/core";
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';

const PersonalMenu = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const userData = useSelector(state => state.auth.data);
    const isLogged = useSelector(state => state.auth.isLoggedIn);
    const userPermission = useSelector(state => state.auth.data.permission ?? []);

    const [currentPage, setCurrentPage] = useState(null);
    const [open, setOpen] = useState(false);
    const [subMenuOpen, setSubMenuOpen] = useState({
        hours: false,
        activities: false
    });

    const content = [];
    if (userPermission.includes('student')) {
        content.push({
            title: "個人頁面",
            id: "user",
            items: [
                {
                    title: "我的時數",
                    id: "hours",
                    subList: [
                        { title: "儀錶板", id: "dashboard" },
                        { title: "時數紀錄", id: "records" }
                    ]
                }, {
                    title: "我的活動",
                    id: "activities",
                    subList: [
                        { title: "報名的活動", id: "applied" },
                        { title: "參加過的活動", id: "attended" }
                    ]
                }, {
                    title: "心得問卷",
                    id: "feedback"
                }, {
                    title: "獎學金紀錄",
                    id: "scholarships"
                }
            ]
        }, {
            title: "服務項目",
            id: "apply",
            items: [
                {
                    title: "個人時數申請",
                    id: "hour"
                }, {
                    title: "獎學金申請",
                    id: "scholarship"
                }
            ]
        })
    }

    if (userPermission.includes('organization')) {
        content.push({
            title: "組織管理",
            id: "group",
            items: userData.organization.map(org => ({
                id: `${org["id"]}/applying`,
                title: org["name"]
            }))
        })
        let offices = userData.organization.filter(org =>
            org["kindno"] !== null ||
            (org["children"] && org["children"].length > 0)
        );
        if (offices.length > 0) {
            content.push({
                title: "組織後台管理",
                id: "office",
                items: offices.map(office => ({
                    id: office["id"],
                    title: office["name"]
                }))
            })
        }
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleSubMenuExpand = (subMenu) => {
        setSubMenuOpen({
            ...subMenuOpen,
            [subMenu]: !subMenuOpen[subMenu]
        })
    };

    useEffect(() => {
        let newPage = props.location.pathname
        if (currentPage == null || currentPage !== newPage) {
            setCurrentPage(newPage);
            setOpen(false);
        }
    }, [currentPage, props.location.pathname])

    return (<>
        {isLogged ? (<>
            <div className={`${classes.PersonalMenuToggler} PersonalMenuToggler`} onClick={handleDrawerOpen}>
                <span className="userName">{userData.name ?? <CircularProgress size={25} thickness={5} style={{ color: theme.palette.primary.main }} />}</span>
                <Icon className="fas fa-caret-down"></Icon>
            </div>
            <Drawer
                anchor="right"
                open={open}
                onClose={handleDrawerClose}
            >
                <div className={classes.PersonalMenu}>
                    <div className="menuHeader">
                        <span className="userName">{userData.name}  您好</span>
                        <Icon onClick={handleDrawerClose} className="fas fa-chevron-right"></Icon>
                    </div>
                    <div className="menuBody">
                        {content.map((list, index) => (
                            <div key={index}>
                                <List>
                                    <ListSubheader className="listTitle">{list.title}</ListSubheader>
                                    {list.items.map(item => (
                                        <div key={item.id}>
                                            <ListItem
                                                button
                                                onClick={() => handleSubMenuExpand(item.id)}
                                                component={!item.subList ? Link : ""}
                                                to={!item.subList ? `/${list.id}/${item.id}` : ""}
                                            >
                                                <ListItemText primary={item.title} />
                                                {item.subList ? (subMenuOpen[item.id] ? <ExpandLess /> : <ExpandMore />) : ""}
                                            </ListItem>
                                            {item.subList ? (
                                                <Collapse in={subMenuOpen[item.id]} timeout="auto" unmountOnExit>
                                                    <List component="div" disablePadding>
                                                        {item.subList.map(subItem => (
                                                            <ListItem button
                                                                key={subItem.id}
                                                                className="nested"
                                                                component={Link}
                                                                to={`/${list.id}/${item.id}/${subItem.id}`}
                                                            >
                                                                <ListItemText primary={subItem.title} />
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </Collapse>
                                            ) : ""}
                                        </div>
                                    ))}
                                </List>
                                {index < content.length - 1 ? <Divider /> : ""}
                            </div>
                        ))}
                    </div>
                    <div className="menuFooter">
                        <Button
                            className="button logout"
                            variant="contained"
                            color="primary"
                            onClick={props.logout}
                        >
                            登出
                        </Button>
                        {(userPermission.includes('admin') || userPermission.includes('part_time')) && (
                            <Button
                                className="button"
                                variant="outlined"
                                color="primary"
                                component={Link}
                                to="/admin"
                            >
                                後台
                            </Button>
                        )}
                    </div>
                </div>
            </Drawer>
        </>) : ''}
    </>);
}

export default withRouter(PersonalMenu);


const useStyles = makeStyles((theme) => ({
    PersonalMenu: {
        width: "300px",
        height: "100%",
        fontSize: "inherit",
        maxWidth: "60vw",
        boxSizing: "border-box",
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        "& *": {
            fontSize: "inherit"
        },
        "& .menuBody": {
            maxHeight: "calc(100% - 140px)",
            overflow: "auto",
            padding: "0 10px",
            "& .listTitle": {
                color: theme.palette.primary.main,
                backgroundImage: `linear-gradient(${theme.palette.background.paper} 70%, ${theme.palette.background.paper}00)`,
                fontWeight: "bold"
            },
            "& .MuiListItemText-root": {
                color: theme.palette.text.primary
            },
            "& .nested": {
                paddingLeft: "50px",
                color: "#555"
            },
            "& > *:last-child": {
                paddingBottom: "100px"
            }
        },
        "& .menuHeader": {
            height: "100px",
            padding: "0 20px",
            display: "flex",
            alignItems: "center",
            fontWeight: "700",
            justifyContent: "space-between",
            backgroundColor: `${theme.palette.type === 'light' ? theme.palette.primary.light : theme.palette.primary.main}`,
            "& .fas": {
                color: `${theme.palette.type === 'light' ? theme.palette.primary.main : 'white'}`,
                cursor: "pointer"
            }
        },
        "& .menuFooter": {
            position: "absolute",
            zIndex: "5",
            bottom: "0",
            width: "100%",
            boxSizing: "border-box",
            padding: "0 10px",
            height: "80px",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
            backgroundImage: `linear-gradient(${theme.palette.background.paper}00 , ${theme.palette.background.paper} 60%)`,
            "& .button": {
                width: '80%',
                margin: "5px",
                marginBottom: 10,
                fontWeight: "bold",
                borderRadius: "8px",
                boxShadow: 'none',
                "&.logout": {
                    color: "#FFFFFF"
                }
            }
        }
    },
    PersonalMenuToggler: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        marginRight: "1vw",
        "& .userName": {
            marginRight: "5px",
            fontWeight: "bold"
        }
    }
}));
